.rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle, .rs-nav .rs-nav-item-active > .rs-nav-item-content, .rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover, .rs-nav .rs-nav-item-active > .rs-nav-item-content:hover, .rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus, .rs-nav .rs-nav-item-active > .rs-nav-item-content:focus, .rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:active, .rs-nav .rs-nav-item-active > .rs-nav-item-content:active {
    color: #f76b82;
}

.rs-nav-subtle.rs-nav-vertical .rs-nav-item > .rs-nav-item-content::before{
    background-color: #f76b82;
}

.rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-nav-item-active > .rs-nav-item-content, .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle, .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-nav-item-active > .rs-nav-item-content:hover, .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover, .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-nav-item-active > .rs-nav-item-content:focus, .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus, .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-nav-item-active > .rs-nav-item-content:active, .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:active {
    color: #f76b82;
}

.rs-nav-subtle.rs-nav-horizontal .rs-nav-item > .rs-nav-item-content::before {
    background-color: #f76b82;
}

// table
.rs-table-cell-header-icon-sort {
    color: #f76b82;
}

.rs-table-cell-header-icon-sort-desc::after {
    color: #f76b82;
}

.rs-table-cell-header-icon-sort-asc::after {
    color: #f76b82;
}

.rs-table-cell-header .rs-table-cell-content {
    font-size: 15px;
    font-weight: 600
}

.rs-custom-row {
    font-size: 12px;
}