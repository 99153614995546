.text-main {
  color: #f76b82;
}

.text-green {
  color: #4caf50;
}

.text-blue {
  color: #2196f3 !important;
}

.text-black {
  color: #000 !important;
}

.text-red {
  color: red;
}

.text-black-45 {
  color: rgba(0, 0, 0, 0.45);
}

.text-black-70 {
  color: rgba(0, 0, 0, 0.7);
}

.bg-black-02 {
  background-color: rgba(0, 0, 0, 0.02);
}